/* eslint-disable */
import $ from 'jquery';
import Inputmask from "inputmask";
import Swiper from 'swiper';
import 'select2';

window.$ = $;
window.jQuery = $;

// switch text
$(() => {
    $('.item__nav').on('click', function() {
        const $item = $(this).parents('.item');
        if($item.hasClass('active')) {
            $item.removeClass('active');
            $item.find('.item__text').slideUp();
        } else {
            $item.addClass('active');
            $item.find('.item__text').slideDown();
        }
    });
});

// reviews
$(() => {
    new Swiper(".reviews .swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
            delay: 3500
        },
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            991: {
                slidesPerView: 3
            }
        }
    });
});

// gallery
$(() => {
    $(document).on('click', '.gallery__thumbs button', function() {
        const src = $(this).data('src');

        $('.gallery__main').css({ backgroundImage: `url('${src}')` });
    });
});

// mob menu
$(() => {
    $('.burger').on('click', () => {
        if($('.mob-menu').hasClass('show')) {
            $('.mob-menu').removeClass('show');
        } else {
            $('.mob-menu').addClass('show');
        }
    });

    if(window.matchMedia('(max-width: 991px)').matches) {
        $('.header .mob-menu__menu li a').on('click', function() {
            if($('.mob-menu').hasClass('show')) {
                $('.mob-menu').removeClass('show');
            }
        });
    }
});

// parallax
// $(() => {
//     if (window.matchMedia('(min-width: 991px)').matches) {
//         $('.start, .about-goods, .gifts-nature').paroller({
//             factor: 0.5,
//             factorXs: 0.1,
//             type: 'background',
//             direction: 'vertical',
//             transition: 'transform 0.2s ease-in'
//         });
//     }
// });

// product
$(() => {
    $('[data-target="#modalProduct"]').on('click', function() {
        const id = $(this).data('id');
        window.location.hash = `product`;
        $.get(`api/getProduct?id=${id}`, (data) => {
            const product = data[0];
            let sizes = null;
            let colors = null;
            if (product.size) {
                sizes = product.size.map((val) => (`<option value="${val}">${val}</option>`)).join('');
            }
            if (product.color) {
                colors = product.color.map((val) => (`<option value="${val}">${val}</option>`)).join('');
            }
            const gallery = product.gallery.map((item) => (`
            <button style="background-image: url('${item.thumbnail}')" data-src="${item.url}"></button>
            `)).join('');
            const mainImageGallery = product.gallery.length ? product.gallery[0].url : 'assets/template/dist/assets/placeholder.png';

            function numberWithSpaces(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
            
            $('#modalProduct .product__title').html(`${product.pagetitle} 
            <span>
            ${numberWithSpaces(+product.old_price) !== '0' ? `
                <span class="old_price">
                    <span class='msoptionsprice-old-cost msoptionsprice-${product.id}'>${numberWithSpaces(+product.old_price)}</span> ₽
                </span>
            ` : ''}
            <span class="product__price">
                <span class="msoptionsprice-cost msoptionsprice-${product.id}">${numberWithSpaces(+product.price)}</span> ₽
            </span>
            </span>`);
            $('#modalProduct .product__description').html(product.description);
            if (sizes) {
                $('[name="options[size]"]').html(sizes);
                $('.product__size').css({ display: 'flex' });
            } else {
                $('.product__size').hide();
            }
            if (colors) {
                $('[name="options[color]"]').html(colors);
                $('.product__color').css({ display: 'flex' });
            } else {
                $('.product__color').hide();
            }
            $('#modalProduct input[name="id"]').val(product.id);
            $('#modalProduct .gallery__main').css({ backgroundImage: `url('${mainImageGallery}')` });
            $('#modalProduct .gallery__thumbs').html(gallery);
        });
    });


    $(window).on('hashchange', function () {
        if(window.location.hash != "#product") {
            $('#modalProduct').modal('hide');
        }
    });
});

// modal cart
$(() => {
    let totalWeight = 0;
    
    // change count
	$(document).on('click', '.modal#modalCart .cart .item__count button', function() {
		const form = $(this).parents('.item__count');
		const input = form.find('input[name=count]');
        let newVal = +input.val();

        if ($(this).hasClass('plus')) newVal++;
        else if ($(this).hasClass('minus')) newVal--;
        
		if (newVal >= 0) {
			input.val(newVal);
			input.trigger('change');
            form.trigger('submit');

            totalWeight = 0;
            $(document).find('#msCart .item').each((i, el) => {
                const weightItem = +$(el).data('weight');
                const count = +$(el).find('input[name=count]').val();
                totalWeight += weightItem * count;
            });
		}
	});

    $('#modalCart').on('show.bs.modal', function() {
        if ($('.msMiniCart .count').text() === '0') {
            miniShop2.Message.info('Корзина пуста');
            return false;
        }
    })

    $(document).on('click', '.msMiniCart', function(e) {
        e.preventDefault();

        if ($(this).find('.count').text() !== '0') {
            $.ajax({  
                type: "GET",  
                url: '/api/cart',
                success:  function(data) {
                    $('#modalCart .modal-content').html(data);

                    $(document).find('#msCart .item').each((i, el) => {
                        const weightItem = +$(el).data('weight');
                        const count = +$(el).find('input[name=count]').val();
                        totalWeight += weightItem * count;
                    });
                }
            });
        }
    });

    // delivery
    $(document).on('select2:open', () => {
        document.querySelector('.select2-search__field').focus();
    });
    $(document).on('click', 'input[name=delivery]', function() {
        const type = $(this).val();
        const $messageDelivery = $(document).find('.form__delivery-message');
        const switchLoader = (visible) => {
            visible ? $(document).find('#loader').css('display', 'flex') : $(document).find('#loader').hide();
        }
        const renderSelect = (data, $select) => {
            let options = [];
            data.forEach((item) => {
                options.push(`<option value="${item.label}" data-iscity="${item.isCity}" data-id="${item.id}">${item.label}</option>`);
            });

            $select.find('select').html(options.join()).select2({
                language: {
                    noResults: () => "Нет результатов"
                }
            });
            $select.show();
        }

        if (type === '11') {
            const $countryItem = $(document).find('.form__item.cse.country');
            const $regionItem = $(document).find('.form__item.cse.region');
            const $cityItem = $(document).find('.form__item.cse.city');
            let isSelectRegion = false;
            let isSelectCity = false;

            switchLoader(true);
            $.get('/api/getcity?type=contries', (data) => {
                if (data.error) {
                    alert(data.message);
                    switchLoader(false);
                    return;
                }
                renderSelect(data.data, $countryItem);
                switchLoader(false);
            });

            $countryItem.find('select').on('select2:select', function() {
                const contryID = $(this).find('option:selected').data('id');

                if (isSelectRegion || isSelectCity) {
                    $regionItem.hide();
                    $cityItem.hide();
                    $regionItem.find('select').val('');
                    $cityItem.find('select').val('');
                    $messageDelivery.hide();
                }

                switchLoader(true);
                $.get(`/api/getcity?type=regions&id=${contryID}`, (data) => {
                    if (data.error) {
                        alert(data.message);
                        switchLoader(false);
                        return;
                    }
                    if (data.isRegions) {
                        renderSelect(data.data, $regionItem);
                    } else {
                        renderSelect(data.data, $cityItem);
                        isSelectCity = true;
                    }
                    switchLoader(false);
                    isSelectRegion = true;
                });
            });
            
            $regionItem.find('select').on('select2:select', function() {
                const selectedOption = $(this).find('option:selected');
                const regionID = selectedOption.data('id');
                const isCity = selectedOption.data('iscity');

                if (isCity) {
                    $cityItem.find('select').val(null).trigger('change');
                    renderSelect([{ label: selectedOption.text(), id: regionID, isCity }], $cityItem);
                    $cityItem.find('select').trigger('select2:select');
                    isSelectCity = true;
                } else {
                    if (isSelectCity) {
                        $cityItem.hide();
                        $messageDelivery.hide();
                    }

                    switchLoader(true);
                    $.get(`/api/getcity?type=cities&id=${regionID}`, (data) => {
                        if (data.error) {
                            alert(data.message);
                            switchLoader(false);
                            return;
                        }
                        renderSelect(data.data, $cityItem)
                        switchLoader(false);
                        isSelectCity = true;
                    });
                }
            });

            $cityItem.find('select').on('select2:select', function() {
                const cityID = $(this).find('option:selected').data('id');

                switchLoader(true);
                $.get(`/api/calccse?city=${cityID}&weight=${totalWeight}`, (data) => {
                    switchLoader(false);

                    if (data.error) {
                        $messageDelivery.addClass('error').text(data.message).show();
                    } else {
                        $messageDelivery.removeClass('error').html(`Стоимость доставки: <span>${data.data}</span> ₽`).show();
                        $(document).find('#msOrder input[name=delivery_cost]').val(data.data);
                    }
                });
            });
        }
    });
});

// scroll
$(() => {
    $('[data-scroll]').on('click', function (e) {
        if (location.pathname === '/') {
            e.preventDefault();
            const id = $(this).attr('href').replace('/', '');

            $('html, body').animate({
                scrollTop: $(id).offset().top
            }, 2000);
        }
    });
});

// start full height
$(() => {
    const calcHeight = () => {
        const windowHeight = window.innerHeight;
        const startHeight = windowHeight - 87;

        $('.start').css({ height: `${startHeight}px` });
    };

    calcHeight();

    $(window).on('resize', () => {
        calcHeight();
    });
});

// cart
$(() => {
    if(+$('.ms2_total_count').text()) {
        $('.msMiniCart').show();
    } else {
        $('.msMiniCart').hide();
    }
    miniShop2.Callbacks.Cart.add.response.success = function(response) {
        if (response.data.total_count) {
            $('.msMiniCart').show();
        } else {
            $('.msMiniCart').hide();
        }
    };
    miniShop2.Callbacks.Cart.change.response.success = function(response) {
        if (response.data.total_count) {
            $('.msMiniCart').show();
        } else {
            $('.msMiniCart').hide();
        }
    };

    if (window.matchMedia('(max-width: 991px)').matches) {
        $(window).scroll(function() {
            if(+$('.ms2_total_count').text()) {
                const height = $(document).height();
                if ($(this).scrollTop() >= height - (window.innerHeight + 500)) {
                    $('.msMiniCart').fadeOut();
                } else {
                    $('.msMiniCart').fadeIn();
                }
            }
        });
    }
});

// one prod
$(() => {
    if ($('.catalog .item').length === 1) {
        $('.catalog .item [data-target="#modalProduct"]').trigger('click');
    }
});

// send review
$(() => {
    $('#modalReview .form').submit((e) => {
        e.preventDefault();

        const sendData = {
            name: $('#modalReview .form #name').val(),
            city: $('#modalReview .form #city').val(),
            age: $('#modalReview .form #profession').val(),
            text: $('#modalReview .form #review').val()
        };

        $.post("api/addcomment", sendData, (response) => {
            const data = JSON.parse(response);

            if (data.success) {
                $('#modalReview .form #name').val('');
                $('#modalReview .form #city').val('');
                $('#modalReview .form #profession').val('');
                $('#modalReview .form #review').val('');
                $('#modalReview').modal('hide');
            }
            alert(data.message);
        });

        return;
    });
});

// about
$(() => {
    $(() => {
        $('.gifts-nature__more').on('click', function() {
            $(this).hide();
            $('.gifts-nature__text').show();
        });
    });
});

// top btn
// $(() => {
//     $(window).scroll(function(){
//         if ($(this).scrollTop() > 100) {
//             $('#up').removeClass('bounceOutDown').addClass('bounceInUp').css({ display: 'flex' });
//         } else {
//             $('#up').removeClass('bounceInUp').addClass('bounceOutDown');
//         }
//     });
    
//     $('#up').click(function(){
//         $('html, body').animate({scrollTop : 0}, 1000);
//         return false;
//     });
// });
